.margin_top_bottom {
  margin: 0.8em 0;
}

.clearfix:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.gnav-util__content__inner {
  .signin-block {
    padding-bottom: 27px;
  }
}

.new-address {
  .form_element.title {
    .errorLabel {
      color: $color-light-red;
    }
  }
}

input[name='PHONE1'] {
  &.checkedEmpty {
    border-color: $color-red;
  }
}

.adpl {
  input[type='tel'] {
    & + label.errorLabel:before {
      color: $color-light-red;
    }
  }
  input[type='text'].error {
    border-color: $color-red;
  }
}

.section-store-locator {
  .store-search-controls {
    .form_element {
      &.city_container,
      &.door_container {
        margin-bottom: 5%;
      }
    }
  }
  .store-locator__map-container {
    .info_window.store-locator__tooltip {
      border: medium none;
    }
  }
}

.wo-inline {
  #wo_online_image {
    bottom: -1px;
    left: 0;
  }
}

@media screen and (min-width: 768px) {
  .wo-inline {
    #wo_online_image {
      bottom: -1px;
      left: 60px;
    }
  }
}
