.mobile_hidden {
  display: none;
  @include breakpoint($medium-up) {
    display: block;
  }
}

.checkout {
  margin-top: 50px;
  &.viewcart .checkout {
    &__panel--continue-buttons {
      .continue-shopping {
        float: left;
        line-height: 46px;
      }
      .continue-checkout {
        float: right;
        width: 94%;
      }
      .checkout-buttons__container {
        float: right;
        width: 100%;
      }
    }
  }
  .no-replenishment {
    .cart-items {
      &__item--price {
        margin-right: 0;
        width: auto;
        text-align: left;
        padding-right: 3%;
      }
      &__item--desc {
        min-height: 90px;
      }
    }
  }
  .adpl {
    .title,
    .address-to-use,
    .state_select,
    .city_select,
    .district_select,
    .default-shipping {
      label {
        display: inline;
      }
    }
  }
  .gift-options {
    .giftwrap {
      text-align: left;
      margin-top: 10px;
    }
  }
  .new-or-returning-radios {
    margin: 10px 0;
    fieldset {
      margin: 5px 0;
    }
  }
  .birthday-container {
    select {
      min-width: initial;
      width: 30%;
      margin-right: 1.5%;
      display: inline;
    }
  }
  #payment-container {
    .sms_bind_mobile {
      margin-top: 25px;
      &::before {
        margin-bottom: 30px;
      }
    }
  }
  #samples-panel {
    .checkout__panel--header {
      .pc_header {
        display: none;
      }
    }
    .product-list {
      border: 1px solid #cbcbcb;
      float: left;
      width: 100%;
      padding: 0;
      margin-top: 10px;
      li.product:nth-child(1) {
        clear: both;
      }
      li.product:nth-child(2n + 0) {
        clear: none;
      }
      li.product:nth-child(2n + 1) {
        clear: none;
      }
      li.product:nth-child(3n + 1) {
        clear: none;
      }
      li.product {
        width: 50%;
        height: 12rem;
        .product-img {
          float: left;
          width: 32%;
          img {
            width: 100%;
          }
        }
        .details {
          float: left;
          margin-left: 0;
          width: 60%;
          .product-brief {
            &__title,
            &__subtitle {
              display: none;
            }
          }
        }
        .skus {
          li.sku {
            float: left;
            width: 100%;
          }
        }
      }
    }
  }
  &__content {
    padding: 0px 10px 0px 10px;
    #shipping-panel {
      .email_promotions {
        padding: 0 1em;
        margin-bottom: 0.8em;
      }
    }
    #sign-in-panel {
      .sign-in-page {
        margin: 0px;
        .sign-in-container {
          .sms-otp {
            &__item {
              padding: 10px 0px 10px 0px;
              .btn-move-up {
                width: 28%;
                height: 48px;
                color: $color-white;
                background-color: $color-drak-black;
                letter-spacing: 10px;
                text-indent: 10px;
              }
              input[name='MOBILE_NUMBER'] {
                width: 70%;
              }
              input[name='PIN_CODE'] {
                width: 100%;
              }
            }
            .return-user-link-container {
              a {
                border-bottom: 1px solid $color-mid-gray;
              }
            }
          }
          .return-user {
            padding: 0px;
            &__item {
              padding: 10px 0px 10px 0px;
              input {
                width: 100%;
              }
            }
            .sms-otp-link-container {
              float: left;
              a {
                border-bottom: 1px solid $color-mid-gray;
              }
            }
          }
          .btn--full {
            height: 56px;
            width: 100%;
            color: $color-white;
            background-color: $color-drak-black;
            letter-spacing: 10px;
            text-indent: 10px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  &-confirmation-page {
    .sign-in-confirmation-panel__content {
      .form-item {
        margin-bottom: 1em;
      }
    }
  }
  &__sidebar {
    .cart-items__item--qty {
      width: 24%;
    }
    .offer-emp,
    .order-summary-empty {
      border-top: medium none;
      padding: 0;
    }
  }
  #checkout_shipping {
    .state_select {
      margin-right: 5px;
    }
    .city_select,
    .state_select,
    .phones {
      width: 48%;
      display: inline-block;
    }
  }
  .checkout_sticky {
    box-shadow: 0 0 0 1px $color-light-gray;
  }
  #sign-in-panel,
  #shipping-panel,
  #review-panel {
    .messages {
      #error_offer_bogo {
        display: none;
      }
    }
  }
  .recommended-item {
    margin-bottom: 10px;
    &__product-image {
      position: relative;
      &__image {
        width: 100%;
      }
      &__image--alt {
        display: none;
      }
      &__badge--text {
        position: absolute;
        left: 5px;
        top: 5px;
      }
    }
    &__product-info {
      padding-top: 0px;
      text-align: left;
      .cart_recommend_product_subname {
        margin: 0.5em 0;
      }
      &__name {
        &__link {
          font-family: $english_font;
          font-size: 15px;
        }
      }
      &__price {
        font-size: 15px;
      }
      .product-brief {
        &__benefit {
          font-size: 15px;
        }
      }
    }
    &__cta {
      text-align: left;
      display: table-cell;
      .button {
        border: none;
      }
    }
    .product-brief {
      &__spp-link {
        display: table-cell;
        padding-left: 10px;
        font-size: 15px;
      }
    }
  }
}

@include breakpoint($medium-down) {
  .checkout {
    &__content {
      #viewcart-panel {
        .cart-items {
          &__item--price,
          &__item--total {
            width: auto;
          }
          &__item--qty {
            margin-top: 0px;
            width: auto;
            &__label {
              vertical-align: text-top;
            }
          }
        }
      }
    }
  }
}

.samples-panel__content {
  ul.skus {
    input[type='checkbox'] {
      position: relative;
      left: 0px;
    }
    .sku.disabled {
      display: none;
    }
  }
  ul.product-list {
    .selected {
      border: none !important;
    }
    .hover {
      border: none !important;
    }
    .product {
      min-height: 200px !important;
      border: none !important;
    }
  }
  .product-list-container {
    .product-brief__subtitle {
      font-size: 17px !important;
      max-width: 27em !important;
    }
  }
}

#samples-panel {
  .checkout {
    &__panel {
      &--header {
        border-bottom: none;
        padding: 0;
        .margin_top_bottom {
          border-bottom: none;
        }
        .remaining {
          p.samples-count-desc {
            padding: 0 0 0 20px;
          }
        }
      }
      &--heading {
        display: block;
        width: 100%;
      }
    }
  }
  .product-list {
    li.product {
      margin: 0 0 0px;
    }
  }
}

body {
  &.order-confirmation {
    .order-details-page {
      .account-utilities {
        display: none;
      }
    }
  }
}

.samples-panel {
  .checkout {
    &__panel--header {
      .remaining {
        .max {
          padding-top: 0px;
        }
      }
    }
  }
  .addsamplestocart {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $font--futura-demi;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: normal;
    line-height: 1;
    padding-top: 2px;
    line-height: 42px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: $color-dark-gray;
    color: $color-white;
    text-align: center;
    text-decoration: none;
    border: solid 1px $color-white;
    -moz-box-shadow: 0 0 0 1px $color-darker-gray;
    -webkit-box-shadow: 0 0 0 1px $color-black;
    box-shadow: 0 0 0 1px $color-black;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0;
    display: inline-block;
    cursor: pointer;
    position: relative;
    top: 1px;
    font-size: 24px;
    float: right;
    margin-right: 18px;
  }
}

@media screen and (min-width: 320px) and (max-width: 452px) {
  .checkout {
    &__content {
      .adpl {
        #checkout_signin_new_user,
        #checkout_signin {
          input[type='text'],
          input[type='password'],
          input[type='tel'] {
            & + label {
              margin-top: -3.05em;
            }
          }
        }
        #new-or-returning-radios {
          input[type='radio'] {
            & + label {
              width: 100%;
              display: inline-block;
            }
          }
        }
      }
    }
  }
}

.checkout #promo-panel .messages .single-message {
  color: $color-red;
}

.opc__cart {
  .checkout__panel--continue-buttons {
    .continue-shopping {
      display: block;
    }
  }
  .viewcart {
    .checkout__content {
      #viewcart-panel {
        .cart-items__item--remove-form {
          .remove_link,
          .remove_link_giftcard {
            width: 30px;
            right: 20px;
          }
        }
      }
      .checkout__panel.mobile_hidden {
        display: none;
      }
    }
    span.choose_samples_txt {
      display: none;
    }
    .checkout__sidebar {
      display: block;
    }
    #recommended-products-panel {
      display: none;
    }
  }
}

#wo_chatbox {
  display: none;
}

.opc__cart .page-footer__secondary {
  .basic.copyright {
    display: block;
  }
}

.viewcart,
.samples,
.wp_samples,
.index,
.confirm {
  .page-footer {
    &__secondary {
      .basic.copyright {
        display: block;
      }
    }
  }
}

.opc__signin {
  #viewcart-panel,
  #order-summary-panel {
    display: none;
  }
}

.opc__shipping {
  .checkout__panel--shipping__edit-address {
    border: none;
    .name-fields,
    .city-state-zip {
      .form-item,
      .form-item.city,
      .form-item.postal-code {
        width: 100%;
      }
    }
    .address-form {
      .form_element.title {
        span {
          display: inline-block;
        }
      }
    }
  }
  .checkout__panel {
    &.finished,
    &.display {
      display: block;
    }
  }
  #sign-in-panel {
    .checkout__panel--content {
      display: block;
    }
  }
  .checkout__content {
    .checkout__panel,
    #shipping-panel {
      border-top: 1px solid $color-light-gray;
    }
  }
}

.opc__review {
  #sign-in-panel,
  #links-panel,
  #gift-card-display {
    display: block;
  }
  .checkout__content {
    #shipping-panel {
      border-top: 1px solid $color-light-gray;
      .shipping-address-display,
      .gift-options-display {
        border-bottom: none;
      }
      .gift-options-display p {
        margin-top: 0.4em;
        margin-bottom: 1em;
      }
      #invoice-change-email-wrapper {
        .field-container {
          float: left;
          .btn--full {
            height: 56px;
            width: 60px;
            color: $color-white;
            background-color: $color-drak-black;
            margin-left: 5px;
            font-size: 15px;
          }
        }
      }
    }
  }
}

.opc__shipping,
.opc__review {
  .checkout__sidebar {
    display: block;
    .order-summary-panel {
      display: none;
    }
  }
  .checkout__content {
    border-bottom: none;
  }
  #offer-code-panel {
    border-bottom: 1px solid $color-light-gray;
    border-top: none;
  }
}

.adpl {
  input {
    &[type='email'] + label:before,
    &[type='tel'] + label:before,
    &[type='password'] + label:before,
    &[type='text'] + label:before {
      content: attr(data-required) attr(placeholder);
    }
  }
  select + label:before {
    content: attr(data-required) attr(placeholder);
  }
}
