.signin-block {
  &__toggle-password {
    &__option {
      &--show {
        @include icon('checkbox--checked');
      }
      &--hide {
        @include icon('checkbox');
      }
      &::before {
        margin-right: 10px;
        color: $color-mid-gray;
      }
    }
  }
}

.page-footer {
  &__primary {
    .social_wechat {
      margin: -8px;
    }
  }
  &__secondary {
    padding-bottom: 75px;
  }
}

.spp-page {
  .product-info {
    .product-offer {
      display: block;
    }
  }
}

.product-offer .product-offer__text {
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
}

.product-offer .product-offer__header {
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
}

.cart-item {
  &__info {
    width: 50.75%;
  }
}

.cart-item {
  &__cart-details {
    width: 24.25%;
  }
}

.cart-block--plural {
  .cart-block {
    &__item-count--plural {
      display: block;
    }
  }
}

.cart-block--added {
  .cart-block {
    &__footer {
      &__subtotal {
        display: block;
      }
    }
  }
}

#cboxLoadedContent:not(.product-waitlist-overlay-cbox, #cboxLoadedContent),
#cboxContent {
  height: auto !important;
}

.page-header__meta-nav {
  .page-header__utils {
    .menu--lvl-1 {
      .menu__list--lvl-1 {
        li.menu__item--lvl-1 {
          width: 50%;
        }
        .trustmark_mobile {
          clear: both;
          text-align: center;
          padding-top: 10px;
        }
      }
    }
  }
}

.icon--weibo {
  background: url(/media/images/global/weibo.jpg) no-repeat center;
  width: 20px;
  height: 17px;
}

.icon--wechat {
  background: url(/media/images/global/wechat.png) no-repeat center;
  width: 35px;
  height: 22px;
  &:before {
    display: none;
  }
}

.cs-page {
  &__content {
    height: auto !important;
  }
  p {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 1.2;
  }
  h1 {
    font-size: 2em;
  }
}

.section-store-locator {
  .store-locator__controls {
    @include breakpoint($small-down) {
      position: static;
    }
  }
  .store-locator__main {
    @include breakpoint($small-down) {
      padding-top: 0px;
    }
  }
  .store-locator__responsive-tab-container {
    padding-bottom: 50px;
  }
}

.adpl {
  .valid_marker,
  .invalid_marker {
    bottom: 20px;
    right: 10%;
  }
}

.page-wrapper {
  .product-grid-wrapper {
    .product-brief {
      &__flag {
        font-size: 16px;
      }
    }
  }
}

.birthday-container select#form--checkout_signin_new_user--field--BIRTH_YEAR {
  padding-right: 30px;
}

.product-full__carousel__slide img {
  max-width: 100%;
}

.store-locator__my-store-saved a {
  display: none;
}

.offer_bank_container {
  margin-left: 10px;
  margin-bottom: 15px;
  padding-bottom: 22px;
  border-bottom: 1px solid $color-light-gray;
  visibility: hidden;
}

.offer_bank_container input[type='radio'] {
  position: static;
  left: auto;
}

img.checkout__offer-code__wallet-item__image {
  width: 144px;
}

.checkout__offer-code__wallet-item {
  text-align: center;
}

.offer_bank_description {
  padding-top: 3%;
  padding-bottom: 3%;
}

.slick-prev:before {
  right: 0px;
  content: ' ';
}

.slick-next:before {
  right: 0px;
  content: ' ';
}

button.slick-prev.slick-arrow {
  @include bordered-triangle(33px, $color-white, left, $color-black);
  left: -38px;
  position: absolute;
  margin-top: 0;
  width: 25px;
  height: 25px;
  padding: 0;
}

button.slick-next.slick-arrow {
  @include bordered-triangle(33px, $color-white, right, $color-black);
  right: -38px;
  position: absolute;
  margin-top: 0;
  width: 25px;
  height: 25px;
  padding: 0;
}

.checkout__offer-code__wallet-item__button-container {
  padding-bottom: 5px;
}

.mpp-v2 {
  .product-brief {
    &__subline,
    &__cta-section {
      float: left;
    }
    &__subline {
      width: 100%;
    }
  }
}

.gnav-offers-formatter {
  .gnav-offer {
    a {
      color: $color-text;
    }
  }
}
