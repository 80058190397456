$opc_update: true;
$base-theme-path: '../../or_base/';

@import 'overrides/overrides';
@import '../../or_base/scss/main';
@import 'cn_fonts.scss';
@import 'sections/_gwp_mobile';
@import 'sections/_store-locator';
@import 'sections/account-reset-password';

// SMACSS/sections/account
@import 'sections/mobile/account/account';

// SMACSS/sections/checkout
@import 'sections/mobile/checkout/checkout';

// SMACSS/sections/all
@import 'sections/mobile/all';

// Power Review files from origins base
@import 'sections/power_review/_power_review.scss';

// Cookie Settings.
@import '../../or_base/scss/sections/cookie_settings_ot';
@import 'sections/one_trust';
@import 'sections/_all';

// Legal consent
@import 'sections/_legal_consent';

.invoice-edit-electronic {
  font-size: small;
}

.slick-next:before {
  content: url(/media/images/OR_SPP_RIGHT_ARROW3.png) !important;
  @if $cr18 == true {
    content: ' ' !important;
  }
}

.slick-prev:before {
  content: url(/media/images/OR_SPP_LEFT_ARROW3.png) !important;
  @if $cr18 == true {
    content: ' ' !important;
  }
}

button.slick-next.slick-arrow {
  opacity: 1;
}

button.slick-prev.slick-arrow {
  opacity: 1;
}

.product-brief {
  .p-w-r {
    display: none !important;
  }
}
.product-full.product-full-v2 {
  .product-full__anchor-link {
    display: none;
  }
}

.collapsed {
  display: none;
}
